import {
  NEXT_PUBLIC_ENABLE_SENTRY,
  NEXT_PUBLIC_ENVIRONMENT,
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_VERSION,
  TRACES_SAMPLE_RATE_FOR_SENTRY_PRODUCTION
} from '@/constants/envs';

import * as Sentry from '@sentry/nextjs';
import ApiPortalBff from '@/http/ApiPortalBff';

export class LogService {
  private static sentryIsEnabled(action: () => void): void {
    if (NEXT_PUBLIC_ENABLE_SENTRY) {
      action();
    }
  }

  static init() {
    if (!NEXT_PUBLIC_ENABLE_SENTRY) {
      return;
    }

    Sentry.init({
      beforeSend(event) {
        if (event.exception?.values?.[0].value.includes('Invariant: attempted to hard navigate to the same URL')) {
          return null;
        }
        return event;
      },
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      tracesSampleRate: TRACES_SAMPLE_RATE_FOR_SENTRY_PRODUCTION,
      environment: NEXT_PUBLIC_ENVIRONMENT,
      release: `portal-ies@${NEXT_PUBLIC_VERSION}`
    });
  }

  public static logError(error: unknown): void {
    this.sentryIsEnabled(() => Sentry.captureException(error));
  }

  public static setUser(user: Sentry.User): void {
    this.sentryIsEnabled(() => Sentry.setUser(user));
  }

  public static addBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
    this.sentryIsEnabled(() => Sentry.addBreadcrumb(breadcrumb));
  }

  public static logEvent(eventName: string, eventDetails?: Sentry.EventHint | undefined): void {
    this.sentryIsEnabled(() =>
      Sentry.captureEvent({
        message: eventName,
        ...eventDetails
      })
    );
  }

  public static logMessage(message: string): void {
    this.sentryIsEnabled(() => Sentry.captureMessage(message));
  }

  public static registerLogHelpdesk(action: string, token: string = undefined): void {
    ApiPortalBff.post(
      '/help-desk',
      { action },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
}
