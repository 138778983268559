import Script from 'next/script';

const MundialeScript = () => (
  <Script
    id="chatMundiale"
    strategy="lazyOnload"
    dangerouslySetInnerHTML={{
      __html: `
          (() => {
          try {
            const mundiale = mundialeSdk || undefined
            function run() {
              if (mundiale) {
                mundialeSdk.hideWhatsButton(false)
                mundialeSdk.hideChatButton(true)
              }
            }

            run()
          } catch (error) {
            // don't need catch
          }
        })(window, document)
        `
    }}
  />
);

export default MundialeScript;
