export const useStorage = (key: string, defaultValue?: any) => {
  function getItem() {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }

  function setItem<T>(value: T) {
    try {
      localStorage.setItem(key, JSON.stringify(value ?? defaultValue));
    } catch (error) {
      //
    }
  }

  return { getItem, setItem };
};
