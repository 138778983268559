import { SMALL_SCREEN_SIZE } from '@/constants/globalVars';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

type SidebarContextType = {
  menuIsOpen: boolean;
  setMenuIsOpen: (menuIsOpen: boolean) => void;
  isLoadingPermissions: boolean;
  setIsLoadingPermissions: (isLoadingPermissions: boolean) => void;
  isOpenMenuMobile: boolean;
  setIsOpenMenuMobile: (isOpenMenuMobile: boolean) => void;
};

const TIME_MENU_CLOSE = 3000;

export const SidebarContext = createContext({} as SidebarContextType);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(true);
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState<boolean>(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(false);
  const value = useMemo(
    () => ({
      menuIsOpen,
      setMenuIsOpen,
      isLoadingPermissions,
      setIsLoadingPermissions,
      isOpenMenuMobile,
      setIsOpenMenuMobile
    }),
    [menuIsOpen, isLoadingPermissions, isOpenMenuMobile]
  );

  useEffect(() => {
    function startCloseOrOpenMenu() {
      const screenSizeIsSmall = window.innerWidth < SMALL_SCREEN_SIZE;
      if (screenSizeIsSmall) {
        setTimeout(() => {
          setMenuIsOpen(false);
        }, TIME_MENU_CLOSE);
      }
    }

    startCloseOrOpenMenu();
  }, []);

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
