export interface ICards {
  version: number;
  elementId: string;
  title: string;
  description: string;
  offsetTop?: number;
  offsetLeft?: number;
  positionArrowFromLeft?: number;
  highlight?: boolean;
  invertCardPosition?: boolean;
}

export interface ITourData {
  id: number;
  page: string;
  cards: ICards[];
}

export const tourMock: ITourData[] = [
  {
    id: 1,
    page: 'suporte',
    cards: [
      {
        version: 1,
        elementId: 'card-whatsapp',
        title: 'Nosso whatsapp para contato',
        description: `Clique no link acima para falar conosco via whatsapp. Temos alguém de prontidão para te ajudar.`
      },
      {
        version: 1,
        elementId: 'card-email',
        title: 'Nosso email para contato',
        description: `Clique no link acima e envie-nos um email.`
      }
    ]
  },
  {
    id: 2,
    page: 'faq',
    cards: [
      {
        version: 1,
        elementId: 'faq-1',
        title: 'Tem dúvidas sobre o uso do Portal?',
        description: `Clique na caixa acima para abrir nosso FAQ`,
        offsetTop: 100
      }
    ]
  },
  {
    id: 3,
    page: 'lista-aprovacao',
    cards: [
      {
        version: 2,
        elementId: 'filterStatusListaAprovacao',
        title: 'Filtro de Status',
        description: `Altere a forma de visualizar a lista de alunos, selecionando o status da avaliação`,
        offsetTop: 45
      },
      {
        version: 1,
        elementId: 'btnFilterToggle',
        title: 'Filtros Avançados',
        description: `Crie buscas personalizadas para facilitar a visualização dos alunos`,
        offsetTop: 35,
        offsetLeft: -220,
        positionArrowFromLeft: 180
      },
      {
        version: 1,
        elementId: 'btnNewStudentsButton',
        title: 'Alunos Novos',
        description: `Visualize apenas os alunos com contratos novos.`,
        offsetTop: 35,
        offsetLeft: -35,
        positionArrowFromLeft: 30
      },
      {
        version: 1,
        elementId: 'btnRenovationStudentsButton',
        title: 'Alunos de Renovação',
        description: `Visualize apenas os alunos que renovaram o contrato.`,
        offsetTop: 35,
        offsetLeft: -65,
        positionArrowFromLeft: 30
      },
      {
        version: 1,
        elementId: 'btnLoadMoreStudents',
        title: 'Carregar mais alunos',
        description: `Carregue mais 20 alunos na lista`,
        invertCardPosition: true
      }
    ]
  }
];
