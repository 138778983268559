import { setCookie as setOneCookies, getCookie as getOneCookie, deleteCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

export type ISetCookie = {
  cookieName: string;
  value: string;
  maxAgeInSeconds: number;
  path?: string;
};

type IDestroyCookie = {
  cookieName: string;
  path?: string;
};

export const setCookie = ({ cookieName, value, maxAgeInSeconds, path }: ISetCookie): void => {
  setOneCookies(cookieName, value, { maxAge: maxAgeInSeconds, path: path || '/' });
};

export const getCookie = (cookieName: string, options?: OptionsType): string | null => {
  try {
    return getOneCookie(cookieName, options);
  } catch (_error) {
    return '';
  }
};

export const destroyCookie = ({ cookieName, path }: IDestroyCookie): void => {
  deleteCookie(cookieName, { path: path || '/' });
};
