import { normalize } from 'normalizr';
import aprovacaoSchema from '@/schemas/approvalStudents';
import { studentStateType } from '@/interfaces/IStudentRedux';

export interface normalizeApproveDataInterface {
  data: {
    aprovacao: any;
  };
}

interface normalizeReturnInterface {
  entities: any;
  result: number;
}

const ANY_VALUE_TO_SIMULATE_ID = 1;

export function normalizeApproveData(data: normalizeApproveDataInterface): normalizeReturnInterface {
  return normalize({ id: ANY_VALUE_TO_SIMULATE_ID, aprovacao: data }, aprovacaoSchema);
}

export const updateNormalizedData = (payload, state): studentStateType => {
  const localState = state;
  const setItem = (item) => {
    const itemExists = localState.byId[item.id];

    if (itemExists) {
      Object.assign(itemExists, item);
    } else {
      localState.byId[item.id] = item;
      localState.allIds.push(item.id);
    }
  };

  Object.keys(payload).forEach((key) => {
    setItem(payload[key]);
  });

  return localState;
};

export const updateAllIds = (payload, stateData): void => {
  Object.keys(payload).forEach((key) => {
    if (!stateData.includes(payload[key].id)) {
      stateData.push(payload[key].id);
    }
  });
};
