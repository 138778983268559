import { ReactNode } from 'react';
import { IntlProvider } from '@/shared/translation';
import { UserProvider } from './UserContext';

type RegisterContextProviderProps = {
  children: ReactNode;
};

const RegisterContextProvider = ({ children }: RegisterContextProviderProps) => (
  <IntlProvider>
    <UserProvider>
      <div>{children}</div>
    </UserProvider>
  </IntlProvider>
);

export default RegisterContextProvider;
