import { createSlice, Draft } from '@reduxjs/toolkit';
import { statusFeatureType } from '@/interfaces/IStatus';
import { IDropDownData } from '@/helpers/utils';

const initialStateStatus: statusFeatureType = {
  statusRenovationStudents: [],
  statusNewStudents: []
};

const statusSlice = createSlice({
  name: 'status',
  initialState: initialStateStatus,
  reducers: {
    setStatusNew: (state: Draft<statusFeatureType>, { payload }: { payload: IDropDownData[] }) =>
      Object.assign(state, {
        statusNewStudents: payload
      }),
    setStatusRenovation: (state: Draft<statusFeatureType>, { payload }: { payload: IDropDownData[] }) =>
      Object.assign(state, {
        statusRenovationStudents: payload
      })
  }
});

export const { setStatusRenovation, setStatusNew } = statusSlice.actions;

export default statusSlice;
