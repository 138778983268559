/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext, CSSProperties } from 'react';
import { SidebarContext } from '@/contexts/SidebarContext';
import { ArrowNextIcon, CloseIcon } from '@/shared/icons';
import { FormattedMessage, useFormattedMessage } from '@/shared/translation';

type ITourProps = {
  elementId: string;
  tourId: number;
  title: string;
  description?: string;
  offsetTop?: number;
  offsetLeft?: number;
  closeButton?: boolean;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  positionArrowFromLeft?: number;
  highlight?: boolean;
  invertCardPosition?: boolean;
  setAddTourCookie: (value: string) => void;
};

const ARROW_SIZE: number = 20;
const DEFAULT_POSITION: number = -500;
const DEFAULT_POSITION_INVERT_CARD: number = 280;
const DEFAULT_TIMEOUT_CARD_START: number = 200;
const DEFAULT_TOP_POSITION: number = 75;

const Tour = ({
  elementId,
  tourId,
  title,
  description = '',
  offsetTop = DEFAULT_TOP_POSITION,
  offsetLeft = 0,
  closeButton = true,
  currentStep,
  setCurrentStep,
  positionArrowFromLeft = 0,
  highlight = true,
  invertCardPosition = false,
  setAddTourCookie
}: ITourProps) => {
  const [horizontal, setHorizontal] = useState<number>(DEFAULT_POSITION);
  const [vertical, setVertical] = useState<number>(DEFAULT_POSITION);
  const [hasElement, setHasElement] = useState<boolean>(false);
  const { menuIsOpen } = useContext(SidebarContext);
  const intl = useFormattedMessage();

  useEffect(() => {
    let position: DOMRect;

    try {
      setTimeout(() => {
        const elementTarget: HTMLElement = document?.getElementById(elementId);

        if (elementTarget) {
          elementTarget.removeAttribute('style');
          setHasElement(true);

          if (tourId === currentStep && highlight) {
            elementTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elementTarget.style.position = 'relative';
            elementTarget.style.zIndex = '35';
            elementTarget.style.borderRadius = '3px';
            elementTarget.style.boxShadow = '5px 4px 10px rgba(255, 255, 255, 0.25)';
            elementTarget.style.pointerEvents = 'none';
          }

          position = elementTarget?.getBoundingClientRect();
          const { top, left } = position;

          const positionHorizontal = left + window.scrollX + offsetLeft + ARROW_SIZE;
          const positionVertical = top + window.scrollY + offsetTop + ARROW_SIZE;

          setHorizontal(positionHorizontal);
          setVertical(positionVertical);

          if (invertCardPosition) {
            setVertical(positionVertical - DEFAULT_POSITION_INVERT_CARD);
          }
        } else {
          setHasElement(false);
          if (tourId === currentStep) {
            setCurrentStep(currentStep + 1);
          }
        }
      }, DEFAULT_TIMEOUT_CARD_START);
    } catch (error) {
      position = null;
    }
  }, [elementId, menuIsOpen, currentStep]);

  function handleClickNextTour() {
    setAddTourCookie(elementId);
    setCurrentStep(closeButton ? 0 : currentStep + 1);
  }

  function handleClickCloseTour() {
    setAddTourCookie(elementId);
    setCurrentStep(0);
  }

  const stepIsVisibleOrHidden = tourId !== currentStep ? 'hidden' : 'show';

  return (
    hasElement && (
      <div className={`tour-${tourId} ${stepIsVisibleOrHidden}`} data-testid="tour" role="note">
        <div className="fixed z-30 bg-black w-ful h-full inset-0 opacity-70" />
        <div
          className="absolute z-40 w-64 p-3 rounded shadow-xl h-40 transition-all duration-100 bg-white"
          style={{ top: `${vertical}px`, left: `${horizontal}px` } as CSSProperties}
        >
          {invertCardPosition ? (
            <div
              className="absolute w-0 h-0 -bottom-[20px] left-5"
              style={{
                borderLeft: `${ARROW_SIZE}px solid transparent`,
                borderRight: `${ARROW_SIZE}px solid transparent`,
                borderTop: `${ARROW_SIZE}px solid #FFF`,
                marginLeft: `${positionArrowFromLeft}px`
              }}
            />
          ) : (
            <div
              className="absolute w-0 h-0 -top-[20px] left-5"
              style={{
                borderLeft: `${ARROW_SIZE}px solid transparent`,
                borderRight: `${ARROW_SIZE}px solid transparent`,
                borderBottom: `${ARROW_SIZE}px solid #FFF`,
                marginLeft: `${positionArrowFromLeft}px`
              }}
            />
          )}

          <button
            type="button"
            onClick={handleClickCloseTour}
            aria-label={intl.formatMessage({ id: 'text.fechar' })}
            title={intl.formatMessage({ id: 'text.fechar' })}
            data-testid="tour-close"
            className="absolute -top-3 -right-2 rounded-full bg-white text-gray-700 px-2 py-1 drop-shadow-lg text-xs font-bold"
          >
            X
          </button>

          <h1 className="font-semibold text-sm leading-4 mb-2 flex">{title}</h1>
          <p className="text-xs">{description}</p>
          <div className="absolute m-2 bottom-0 left-0 right-0 flex justify-between w-auto">
            {currentStep > 1 && (
              <button
                type="button"
                onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
                title={intl.formatMessage({ id: 'text.anterior' })}
                data-testid="tour-previous"
                className="px-3 py-1 text-sm rounded bg-[#232F3B60] hover:bg-[#232F3B80] disabled:bg-gray-400 transition-all duration-300 text-center text-white font-medium"
              >
                <small className="flex align-center justify-center">
                  <ArrowNextIcon className="text-lg -ml-2" style={{ transform: 'rotate(180deg)' }} />{' '}
                  <FormattedMessage id="text.anterior" />
                </small>
              </button>
            )}
            <button
              type="button"
              onClick={handleClickNextTour}
              title={intl.formatMessage({ id: 'text.proximo' })}
              data-testid="tour-next"
              className="px-3 py-1 text-sm ml-auto rounded bg-orange-500 hover:bg-orange-600 transition-all duration-300 text-center text-white font-medium"
            >
              {closeButton ? (
                <small className="flex align-center justify-center">
                  <FormattedMessage id="text.fechar" /> <CloseIcon className="text-lg -mr-2 ml-1" />
                </small>
              ) : (
                <small className="flex align-center justify-center">
                  <FormattedMessage id="text.proximo" /> <ArrowNextIcon className="text-lg -mr-2" />
                </small>
              )}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default Tour;
