import { createContext, useState, ReactNode, useMemo } from 'react';
import { COOKIE_MAIN_PORTAL } from '@/constants/globalVars';
import { getCookie } from '@/shared/cookie';

type UserContextType = {
  userIsAuthenticate: boolean;
  verifyUserAuthentication: () => Promise<void>;
  canAccessUserManagement: boolean;
  setCanAccessUserManagement: (value: boolean) => void;
  canAccessHelpdeskPage: boolean;
  setCanAccessHelpdeskPage: (value: boolean) => void;
};

export const UserContext = createContext({} as UserContextType);

type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [userIsAuthenticate, setUserIsAuthenticate] = useState<boolean>(false);
  const [canAccessUserManagement, setCanAccessUserManagement] = useState<boolean>(false);
  const [canAccessHelpdeskPage, setCanAccessHelpdeskPage] = useState<boolean>(false);

  async function verifyUserAuthentication() {
    const isAuthenticateInPortal = getCookie(COOKIE_MAIN_PORTAL);

    if (isAuthenticateInPortal) {
      setUserIsAuthenticate(true);
    }
  }

  const data = useMemo(
    () => ({
      verifyUserAuthentication,
      userIsAuthenticate,
      canAccessUserManagement,
      setCanAccessUserManagement,
      canAccessHelpdeskPage,
      setCanAccessHelpdeskPage
    }),
    [userIsAuthenticate, canAccessUserManagement, canAccessHelpdeskPage]
  );

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};
