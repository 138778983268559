import { createSlice } from '@reduxjs/toolkit';
import { initialStateStudent, studentStateType } from '@/interfaces/IStudentRedux';
import { updateNormalizedData, normalizeApproveData } from '@/helpers/normalize';

const sliceStudents = createSlice({
  name: 'sliceStudents',
  initialState: initialStateStudent,
  reducers: {
    addStudents: (state, { payload }) => updateNormalizedData(payload, state),
    removeStudentFromEntitiesById: (state, { payload }) => {
      const localState: studentStateType = state;
      localState.allIds = localState.allIds.filter((id) => id !== payload);
      delete localState.byId[payload];

      return localState;
    },
    resetAllStudents: () => initialStateStudent,
    resetAndAddNewStudents: (state, { payload }) => {
      const localState: studentStateType = state;
      localState.allIds = [];
      localState.byId = {};

      return updateNormalizedData(payload, localState);
    }
  }
});

export const { removeStudentFromEntitiesById, resetAllStudents, resetAndAddNewStudents } = sliceStudents.actions;

export const addDataApproveStudents = (data) => (dispatch) => {
  const { entities } = normalizeApproveData(data);

  const { alunos } = entities;

  dispatch(sliceStudents.actions.addStudents(alunos));
};

export default {
  students: sliceStudents.reducer
};
