import { createSlice } from '@reduxjs/toolkit';
import { updateAllIds } from '@/helpers/normalize';
import { initialStateStudents } from '@/interfaces/IStudents';

const studentSlice = createSlice({
  name: 'student',
  initialState: initialStateStudents,
  reducers: {
    addStudentDataIds: (state, { payload }) => {
      updateAllIds(payload, state.studentsIds);
    },
    removeStudentFromFeaturesById: (state, { payload }) => {
      const newState = state;
      newState.studentsIds = state.studentsIds.filter((id) => id !== payload);
      return newState;
    },
    resetFeatureStudent: (state) => {
      Object.assign(state, initialStateStudents);
    },
    setStudentIdSelected: (state, { payload }) => {
      const newState = state;
      newState.selectStudentId = payload;
      return newState;
    }
  }
});

export const { addStudentDataIds, removeStudentFromFeaturesById, setStudentIdSelected, resetFeatureStudent } =
  studentSlice.actions;

export default studentSlice;
