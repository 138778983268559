export type dataVisitedPage = {
  url: string;
};

export interface dataUserType {
  visitedPages: dataVisitedPage[];
}

export const initialStateDataUser: dataUserType = {
  visitedPages: []
};
