import { createSlice } from '@reduxjs/toolkit';
import { IPermissionsFactory } from '@/factories/auth/PermissionsFactory';

export type UserType = {
  name: string;
  email: string;
  username: string;
  document: string;
};
export interface IUserLogged {
  user: UserType;
  token: string;
  permissions?: IPermissionsFactory[];
  activeRole?: IPermissionsFactory;
  isAnimaRobot?: boolean;
}

const initialStateUserLogged: IUserLogged = {
  user: null,
  token: null,
  permissions: [],
  activeRole: null,
  isAnimaRobot: false
};

const userLoggedSlice = createSlice({
  name: 'userLogged',
  initialState: initialStateUserLogged,
  reducers: {
    setUserLogged: (state: IUserLogged, { payload }: { payload: IUserLogged }) => Object.assign(state, payload),
    setPermissions: (state: IUserLogged, { payload }: { payload: IPermissionsFactory[] }) =>
      Object.assign(state, { permissions: payload }),
    setActiveRole: (state: IUserLogged, { payload }: { payload: IPermissionsFactory }) =>
      Object.assign(state, { activeRole: payload })
  }
});

export const { setUserLogged, setPermissions, setActiveRole } = userLoggedSlice.actions;

export default userLoggedSlice;
