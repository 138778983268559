import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import features from '@/core/redux/features/index';
import entities from '@/entities/index';

interface IStore {
  asyncReducers: { [key: string]: Reducer };
  injectReducer: (key: string, reducer: Reducer) => void;
}

const baseStore = configureStore({
  reducer: {
    entities: combineReducers(entities),
    features: combineReducers(features)
  }
});

type EnhancedStoreUpdated = typeof baseStore & IStore;

const store: EnhancedStoreUpdated = baseStore as EnhancedStoreUpdated;

store.asyncReducers = {};

store.injectReducer = (appName: string, reducers: Reducer | { [key: string]: Reducer }) => {
  if (Object.keys(reducers).length === 0) {
    store.asyncReducers[appName] = reducers as Reducer;
  } else {
    store.asyncReducers[appName] = combineReducers(reducers as { [key: string]: Reducer });
  }
  store.replaceReducer(
    combineReducers({
      entities: combineReducers(entities),
      features: combineReducers(features),
      ...store.asyncReducers
    })
  );
};

export { store };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
