import { avaliationSlice } from '@/features/avaliation/slices';
import student from './student/slices';
import status from './status/slices';
import userLogged from './userLogged/slices';
import dataUser from './dataUser/slices';

const features = {
  studentFeature: student.reducer,
  statusFeature: status.reducer,
  userLoggedFeature: userLogged.reducer,
  dataUserFeature: dataUser.reducer,
  avaliationFeature: avaliationSlice.reducer
};

export default features as typeof features;
