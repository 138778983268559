import { createSlice } from '@reduxjs/toolkit';

enum StatusModalEnum {
  CLOSED = 'CLOSED',
  IN_APPROVAL = 'IN_APPROVAL',
  GO_NEXT_STUDENT = 'GO_NEXT_STUDENT',
  FINISH_LIST = 'FINISH_LIST',
  TO_REPROVE = 'TO_REPROVE',
  TO_PENDING = 'TO_PENDING',
  PROCESSING = 'PROCESSING'
}

const initialStateAvaliation = {
  statusModal: StatusModalEnum.CLOSED,
  hasPendingStudent: false
};

const avaliationSlice = createSlice({
  name: 'avaliation',
  initialState: initialStateAvaliation,
  reducers: {
    setStatusModal: (state, { payload }) => {
      const newState = state;
      newState.statusModal = payload;
      return newState;
    },
    setHasPendingStudent: (state, { payload }) => {
      const newState = state;
      newState.hasPendingStudent = payload;
      return newState;
    }
  }
});

export { StatusModalEnum, avaliationSlice };
export const { setStatusModal } = avaliationSlice.actions;
