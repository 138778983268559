/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Tour from '@/base/Tour';
import { ICards, ITourData } from '@/mocks/tour';
import { useStorage } from '@/hooks/useStorage';
import { castToBoolean } from '@/helpers/utils';
import { STORAGE_TOUR } from '@/constants/globalVars';

type TourContextType = {
  cards: ICards[];
};

type ICookie = {
  page: string;
  cards: string;
  version: number;
};

type ITourContextProps = {
  children: any;
  data: ITourData[];
};

const INITIAL_STEP = 1;
const DEFAULT_STATUS_TOUR = true;

const TourContext = createContext({} as TourContextType);
export const TourProvider = ({ children, data: tourMock }: ITourContextProps) => {
  const router = useRouter();
  const [tourIsAvailable, setTourIsAvailable] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(INITIAL_STEP);
  const [cardsTour, setCardsTour] = useState<ICards[]>([] as ICards[]);
  const [addTourCookie, setAddTourCookie] = useState<string>();

  const currentPage: string = router.pathname.replace('/', '');

  const { getItem: getTourLocalStorage, setItem: setTourLocalStorage } = useStorage(STORAGE_TOUR, []);
  const { getItem: getTourStatusLocalStorage } = useStorage(`${STORAGE_TOUR}Status`, DEFAULT_STATUS_TOUR);

  function checkIsTourIsAvailable() {
    const isTourAvailable = castToBoolean(process.env.NEXT_PUBLIC_SHOW_ONBOARDING_TOUR);
    const portalieTourStatus = castToBoolean(getTourStatusLocalStorage());

    if (portalieTourStatus && isTourAvailable) {
      setTourIsAvailable(true);
    }
  }

  useEffect(() => {
    checkIsTourIsAvailable();
  }, []);

  function getLastVersion(filteredCards: ICards[]): number {
    const lastVersion: ICards = filteredCards?.sort((prev: ICards, next: ICards) =>
      next.version > prev.version ? 1 : 0
    )[0];
    return lastVersion?.version;
  }

  function filteredCardsToShow(portalieTour: ICookie[], currentVersionOfTour: number): ICookie[] {
    const filterCookie: ICookie[] = portalieTour?.filter(
      (card: ICookie) => card.page === currentPage && card.version <= currentVersionOfTour
    );

    return filterCookie;
  }

  useEffect(() => {
    const portalieTour: ICookie[] = getTourLocalStorage();

    const tourData: ITourData = tourMock?.find((item: ITourData) => item.page === currentPage);
    const filteredCards: ICards[] = tourData?.cards;
    const currentVersionOfTour: number = getLastVersion(filteredCards);

    const cardsOnCurrentPage: ICookie[] = filteredCardsToShow(portalieTour, currentVersionOfTour);
    const hasLastVersion: boolean = cardsOnCurrentPage?.some((card: ICookie) => card.version === currentVersionOfTour);
    const cardsLastVersion: ICards[] = filteredCards?.filter((card: ICards) => card.version === currentVersionOfTour);

    if (cardsOnCurrentPage.length > 0) {
      if (!hasLastVersion && cardsLastVersion?.length > 0) {
        setCardsTour(cardsLastVersion);
      } else {
        setCardsTour([]);
      }
    } else {
      setCardsTour(filteredCards);
    }

    setCurrentStep(INITIAL_STEP);
  }, [currentPage]);

  function validStorage() {
    try {
      const portalieTour: ICookie[] = getTourLocalStorage();

      const hasCookieSaved: boolean = portalieTour?.some(
        (cookie: ICookie) => cookie.page === currentPage && cookie.cards.includes(addTourCookie)
      );

      const newCookie: ICookie[] = [...portalieTour];

      if (!hasCookieSaved) {
        newCookie.push({
          page: currentPage,
          cards: addTourCookie,
          version: cardsTour?.find((card: ICards) => card.elementId === addTourCookie)?.version
        });
      }

      setTourLocalStorage<ICookie[]>(newCookie);

      return newCookie;
    } catch (error: unknown) {
      return [];
    }
  }

  useEffect(() => {
    if (addTourCookie) {
      validStorage();
    }
  }, [addTourCookie]);

  const cards: { cards: ICards[] } = useMemo(() => ({ cards: cardsTour }), [cardsTour]);

  return (
    <TourContext.Provider value={cards}>
      {tourIsAvailable &&
        cardsTour?.length > 0 &&
        cardsTour.map((tour: ICards, index: number) => (
          <Tour
            key={tour.elementId}
            tourId={index + 1}
            elementId={tour.elementId}
            closeButton={cardsTour.length === index + 1}
            title={tour.title}
            description={tour.description}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            offsetTop={tour.offsetTop}
            offsetLeft={tour.offsetLeft}
            positionArrowFromLeft={tour.positionArrowFromLeft}
            highlight={tour.highlight}
            invertCardPosition={tour.invertCardPosition}
            setAddTourCookie={setAddTourCookie}
          />
        ))}
      {children}
    </TourContext.Provider>
  );
};
